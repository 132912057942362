import { Blocks } from "@/components/Blocks";
import { getArticles } from "@/components/Blocks/Blog/Blog1";
import { GetStaticProps } from "next";
import Error from "next/error";
import { useRouter } from "next/router";
import { client } from "tina/__generated__/client";
import { useTina } from "tinacms/dist/react";
import { Page } from "../components/Page";

export type PageData = Awaited<
  ReturnType<(typeof client)["queries"]["pageQuery"]>
> & { articles?: Awaited<ReturnType<typeof getArticles>> | undefined };

export default function Home(props: PageData) {
  const { data } = useTina(props);
  const content = data?.page;

  const router = useRouter();

  if (!router.isFallback && !props.variables.relativePath) {
    return <Error statusCode={404} />;
  }

  return (
    <Page {...content} global={props.data.global}>
      {content && <Blocks {...content} global={props.data.global} />}
    </Page>
  );
}

export const getStaticProps: GetStaticProps = async ({ draftMode = false }) => {
  const data: PageData = await client.queries.pageQuery({
    relativePath: "home.mdx",
  });

  return {
    props: {
      ...data,
    },
  };
};
